import { PageProps } from "gatsby";
import React from "react";

import { Software } from "../components/pages/Software";

import { snProps } from "../js/utils";

const SoftwarePage = (props: PageProps) => (
  <Software {...{ ...snProps, ...props }} />
);

export default SoftwarePage;
